import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function TeamList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "name", sortable: false, label: "Name" },
        { key: "type", sortable: false, label: "type" },
        { key: "status", sortable: false, label: "status" },
        { key: "manager_details.name", sortable: false, label: "manager" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalTeamList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const teamsMetaData = ref([]);
    const teamStatusOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalTeamList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            statusFilter,
        ],
        () => {
            refetchData();
        }
    );

    const fetchTeamList = (ctx, callback) => {
        store
            .dispatch("app-teams/fetchTeams", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
            })
            .then(response => {
                const team = response.data.data;
                const { total } = response.data.meta;
                teamStatusOption.value = response.data.status;

                callback(team);
                totalTeamList.value = total;
                teamsMetaData.value = team;
            })
            .catch(error => {
                console.error(error);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching teams",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error fetching teams`,
                      icon:'AlertTriangleIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                        },
                        {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                        })
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchTeamList,
        tableColumns,
        perPage,
        currentPage,
        totalTeamList,
        teamsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        teamStatusOption,
        statusFilter
    };
}
